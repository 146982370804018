const ID_TOKEN_KEY = 'id_token';
const USER_KEY = 'user';

// const APP_USER = 'user';

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getUser = () => {
  if (
    window.localStorage.getItem(USER_KEY) &&
    typeof window.localStorage.getItem(USER_KEY) == 'object'
  ) {
    return JSON.parse(window.localStorage.getItem(USER_KEY));
  }
  return {};
};

export const saveUser = (user) => {
  window.localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const saveToken = (token) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
  // window.localStorage.setItem(APP_USER, user.user.first_name);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken, saveUser, getUser };
