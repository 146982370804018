import ApiService from "@/core/services/api.service";

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export const INFLUENCER_ACTION = "INFLUENCER_ACTION";
export const INFLUENCER_MUTATION = "INFLUENCER_MUTATION";
export default {
  state: {
    selected_influencer: []
  },
  getters: {
    InfluencerGetters(state) {
      return state.selected_influencer;
    }
  },
  actions: {
    [INFLUENCER_ACTION](state, payload) {
      ApiService.get("/influencers/" + payload).then(response => {
        // console.log('INFLUENCER',response.data.data);
        state.commit(INFLUENCER_MUTATION, response.data.data);
      });
    }
  },
  mutations: {
    [INFLUENCER_MUTATION](state, payload) {
      // console.log("mutations of INFLUENCER", payload)
      state.selected_influencer = payload;
    }
  }
};
