import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export const INF_CAMPAIGN_ACTION = "INF_CAMPAIGN_ACTION";
export const INF_CAMPAIGN_MUTATION = "INF_CAMPAIGN_MUTATION";
export const INF_ADS_MUTATION = "INF_ADS_MUTATION";
export const INF_ADS_ACTION = "INF_ADS_ACTION";
export default{
  state: {
    Inf_campaign_array:[],
    Perf_ads_array:[],
  },
  getters:{
    PerfCampaignGetters(state) {
      return state;
    },
  },
  actions: {
    [INF_CAMPAIGN_ACTION](state, payload){
        // console.log("action")
      state.commit(INF_CAMPAIGN_MUTATION,payload);
    },
    [INF_ADS_ACTION](state, payload){
      // console.log("action")
    state.commit(INF_ADS_MUTATION,payload);
  },
},
mutations: {
    [INF_CAMPAIGN_MUTATION](state,payload){
        // console.log("mutations")
        state.Inf_campaign_array = payload;
    },
    [INF_ADS_MUTATION](state,payload){
      // console.log("mutations")
      state.Perf_ads_array = payload;
  },
  },
}