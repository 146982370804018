import Vue from "vue";
import Vuex from "vuex";
import ApiService from "../api.service";

Vue.use(Vuex);
export const CAMPAIGN_ACTION = "CAMPAIGN_ACTION";
export const CAMPAIGN_MUTATION = "CAMPAIGN_MUTATION";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const ALL_CAMPAIGN_DATA = "ALL_CAMPAIGN_DATA";
export const SET_ALL_CAMPAIGN_DATA = "SET_ALL_CAMPAIGN_DATA";
export const SELECTED_CAMPAIGN_ID = "SELECTED_CAMPAIGN_ID";

// export const DELETED_CAMPAIGN = "DELETED_CAMPAIGN";

export default {
  state: {
    selected_campaign_id: [],
    campaign_list: [],
  },
  getters: {
    CampaignGetters(state) {
      return state.selected_campaign_id;
    },
    CampaignArrayGetters(state) {
      return state.campaign_list;
    },
  },
  actions: {
    [CAMPAIGN_ACTION](state, payload) {
      // console.log("action")
      state.commit(CAMPAIGN_MUTATION, payload);
    },
    //  Get All Campaigns
    [ALL_CAMPAIGN_DATA](state, payload) {
      let dataToPost = {
        client_id: payload.client_id,
        month: payload.month,
        year: payload.year,
      };
      ApiService.post("/campaigns/all-performance", dataToPost).then(
        async (response) => {
          let campValue = response.data.data;
          for (let i = 0; i < campValue.length; i++) {
            let res = await ApiService.post("/ads/media-plan", {
              campaign_id: campValue[i]._id,
            });
            response.data.data[i].media_plan = res.data.data.data;
          }
          console.log(response, "responseresponseresponse");
          state.commit(SET_ALL_CAMPAIGN_DATA, response.data.data);
        }
      );
    },

    [CREATE_CAMPAIGN](state, payload) {
      console.log("");
    },
  },
  mutations: {
    [CAMPAIGN_MUTATION](state, payload) {
      // console.log("mutations")
      state.selected_campaign_id = payload;
    },
    [SET_ALL_CAMPAIGN_DATA](state, payload) {
      state.campaign_list = payload;
    },
  },
};
