import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import client_view_section from "./client_view_section.module";
import performance_managment from "./performance_managment.module";
import infulencer_management from "./infulencer_management.module";
import influencer_view_section from "./influencer_view_section.module";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    client_view_section,
    performance_managment,
    infulencer_management,
    influencer_view_section
  }
});
