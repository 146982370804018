import ApiService from "@/core/services/api.service";

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export const CLIENT_ACTION = "CLIENT_ACTION";
export const CLIENT_MUTATION = "CLIENT_MUTATION";
export default {
  state: {
    selected_client: [],
  },
  getters: {
    ClientGetters(state) {
      return state.selected_client;
    },
  },
  actions: {
    [CLIENT_ACTION](state, payload) {
      return new Promise((resolve, reject) => {
        ApiService.get("/clients/" + payload)
          .then((response) => {
            // console.log('client',response.data.data);
            state.commit(CLIENT_MUTATION, response.data.data);
            resolve();
          })
          .catch((err) => {
            reject();
          });
      });
    },
  },
  mutations: {
    [CLIENT_MUTATION](state, payload) {
      // console.log("mutations of client", payload)
      state.selected_client = payload;
    },
  },
};
